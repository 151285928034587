import { useState, useEffect, useReducer } from "react";
import { useFormik } from "formik";
import { object, string, boolean } from "yup";
import { IntialState } from "../context";
import userReducer from "../context/userReducer";
import GoogleTagManager from "../util/analytics/gtm";

type useAuthOptions = {
  initialState?: any;
  redirect?: boolean;
  onSuccess?: () => void;
};

function useAuth(options?: useAuthOptions) {
  const [isNewUser, setIsNewUser] = useState(false);
  const [otpGenerated, setOtpGenerated] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [timer, setTimer] = useState(0);
  const [countryCode, setCountryCode] = useState("91");
  const [location, setLocation] = useState({
    countryName: "India",
    countryCode: "IND",
    phoneCode: "91",
  });

  const [store, dispatch] = useReducer(userReducer, IntialState);

  /**
   * RegEx for Mobile Number Input
   */
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  /**
   * Validation Schema for Mobile Input
   */
  const schema = object().shape({
    mobile: string()
      .matches(phoneRegExp, "Mobile number is not valid")
      .min(10)
      .max(10)
      .required("Valid mobile number is required"),
  });

  /**
   * Formik Instance for Mobile Input Screen
   */
  const loginFormik = useFormik({
    initialValues: {
      mobile: "",
    },
    validationSchema: schema,
    validateOnMount: true,
    onSubmit: async (value, { setSubmitting }) => {
      try {
        const { data } = await store.api.generateOTP({
          countryCode,
          mobile: value.mobile.toString(),
        });

        if (!data.userExists) {
          setIsNewUser(true);
        } else {
          setIsNewUser(false);
        }
        setSubmitting(false);
        setOtpGenerated(true);
      } catch (error) {
        setSubmitting(false);
      }
    },
  });

  /**
   * Formik Instance for Verification and Registration Screen
   */
  const otpFormik = useFormik<{
    isNewUser: boolean;
    otp: string;
    email: string;
    name: string;
    referralCode: string;
  }>({
    initialValues: {
      isNewUser,
      otp: "",
      email: "",
      name: "",
      referralCode: "",
    },
    validationSchema: object().shape({
      isNewUser: boolean(),
      otp: string()
        .matches(/^[0-9]{4}$/g, "Enter Four digit OTP")
        .required("Enter received OTP"),
      email: string().when("isNewUser", {
        is: true,
        then: string()
          .email("Enter Valid Email Address")
          .required("Email is required"),
      }),
      name: string().when("isNewUser", {
        is: true,
        then: string().required("Name is required"),
      }),
    }),
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      let verifyData: any;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let consumerId;
      if (!otpVerified) {
        try {
          /**
           * Only Verify OTP once, if user registration fails
           * it should not call otp verification again.
           */
          const verifyOTPConfig = {
            countryCode: "91",
            mobile: loginFormik.values.mobile,
            otp: values.otp,
            type: "mobile",
          };

          verifyData = await store.api.verifyOTP(verifyOTPConfig);

          const { data } = verifyData;

          if (data.code === 200) {
            setOtpVerified(true);
            localStorage.setItem("xtoken", data.token);
            localStorage.removeItem("stoken");
            localStorage.setItem("mobileNumber", data.user?.phoneNumber);
          }
          if (!isNewUser) {
            localStorage.setItem("memberId", data.user.id);
            consumerId = data.user.id;
          }
        } catch (error) {
          console.log(error);
          /**
           * Catch the API failure response and display error message
           */
          setSubmitting(false);
          if (!otpVerified) {
            setErrors({
              otp: "Invalid Otp",
            });
          }
        }
      }

      /**
       * If user does not exists then register a new consumer
       * with required payload
       */
      if (isNewUser) {
        const [firstName, lastName] = values.name.split(" ");
        const tagMasterResult =  await store.api.getTagMaster();
        let  tagArr:any=[]
        if(tagMasterResult.data?.data.length > 0){
        tagArr.push({"id": tagMasterResult.data.data[0].id,"name": tagMasterResult.data.data[0].name,"color": tagMasterResult.data.data[0].color})
        }
        const { data: consumer } = await store.api.createConsumer({
          phoneNumber: loginFormik.values.mobile.toString(),
          email: values.email,
          parentReferenceCode: values.referralCode,
          firstName,
          lastName: lastName || "",
          location: {
            countryCode: location.countryCode,
            countryName: location.countryName,
            phoneCode: location.phoneCode,
          },
          vendorCode: "mgp",
          meta: {
            registrationInfo: {
              appVersion: `${navigator.appCodeName}/${navigator.appVersion}`,
              deviceType: "Mobile_Website",
              deviceId: "",
              dob: "",
            },
            tags:tagArr ||  []
          },
        });

        if (consumer.code === 200) {
          /**
           * Set MemberId and token to localstorage for further API calls
           * Axios will use 'xtoken' from localStorage for
           * consumer facing API calls
           */
          localStorage.setItem("memberId", consumer.user.id);
          localStorage.setItem("xtoken", consumer.token);
          localStorage.setItem("mobileNumber", consumer.user.phoneNumber);
          localStorage.setItem("isNewUser", JSON.stringify(isNewUser));
          consumerId = consumer.user.id;
          verifyData.data.user = consumer.user;
          gtmRegistration(consumer);
          gtmLogin();
        }
      } else {
        gtmLogin();
      }

      /**
       * Dispatch action to store user profile to redux state
       */
      dispatch({ type: "ADD_USER", payload: verifyData.data?.user });

      if (options?.onSuccess) {
        options.onSuccess();
      }
    },
  });

  /**
   * Resend OTP call
   */
  const handleResendOTP = () => {
    setTimer(30);
    // setState({ ...state, otpResend: true });
    otpFormik.setErrors({});
    otpFormik.setFieldValue("otp", "");
    store.api.generateOTP({
      countryCode: "91",
      mobile: loginFormik.values.mobile,
    });
  };

  useEffect(() => {
    otpFormik.setFieldValue("referralCode", localStorage.getItem("rc") || "");
    // eslint-disable-next-line
  }, []);

  /**
   * If the user inputs four digits then call otp verification API
   */
  useEffect(() => {
    if (!isNewUser && otpFormik.values.otp.length === 4) {
      otpFormik.handleSubmit();
    }
    // eslint-disable-next-line
  }, [otpFormik.values.otp]);

  /**
   * Timer for OTP
   */
  useEffect(() => {
    let clearTimer: NodeJS.Timeout;
    if (timer > 0) {
      clearTimer = setTimeout(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearTimeout(clearTimer);
    };
  }, [timer]);

  const gtmLogin = () => {
    //#region // *WebEngage [10] - Login Completed
    let registrationPlatform = "QR Scan";
    const webengageDataLayer = {
      platform: registrationPlatform,
      userType: "Member",
    };
    GoogleTagManager.LoginCompleted(webengageDataLayer);
    //#endregion *WebEngage [10] - Login Completed
  };

  const gtmRegistration = (consumer: any) => {
    //#region  // *WebEngage [22] - Registration Completed
    const fbObjRegister: any = {};
    fbObjRegister.Identity = consumer?.user?.id;
    fbObjRegister.Name = consumer?.user?.firstName;
    fbObjRegister.Email = consumer?.user?.email ? consumer.user?.email : "";
    fbObjRegister.Phone = consumer?.user?.phoneNumber;
    fbObjRegister.Gender = consumer?.user?.gender;

    const webengageRegistrationDataLayer = {
      id: consumer.user.id,
      firstName: consumer.user.firstName,
      lastName: consumer.user.lastName || "",
      mobile: consumer.user.phoneNumber,
      Registration_date: consumer.user.createdAt,
      email: consumer.user.email,
      Gender: consumer.user?.gender || "female",
      referral_code: consumer.user.referenceCode,
      parentReferalCode: localStorage.getItem("rc") || "",
      googleRegistered: false,
      facebookRegistered: false,
      medium: "Phone Number",
      referral: "rc" in localStorage,
      userType: "Member",
    };
    fbObjRegister.webengage = webengageRegistrationDataLayer;
    GoogleTagManager.completeRegistration(fbObjRegister);
    //#endregion WebEngage [22] - Registration Completed
  };

  return {
    loginFormik,
    otpGenerated,
    otpFormik,
    otpVerified,
    locationState: {
      location,
      countryCode,
    },
    setLocationState: {
      setCountryCode,
      setLocation,
    },
    store,
    timer,
    handleResendOTP,
    isNewUser,
  };
}

export default useAuth;
