import React from "react";
// import BackIcon from "../assets/images/back-icon.svg";
import MyglammLogo from "../assets/images/myglamm-logo.png";

const Header = () => {
  return (
    <nav className="navbar">
      {/* <img className="back-icon" src={BackIcon} alt="backicon" /> */}
      {/* <Link to="#"> */}
      <a href={process.env.REACT_APP_DOMAIN_URL || "#"}>
        <img className="brand-logo" src={MyglammLogo} alt="myglamm-logo" />
      </a>
      {/* </Link> */}
    </nav>
  );
};

export default Header;
