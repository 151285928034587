import React, { useEffect, useReducer, useState } from "react";
import Login from "./Login";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { IntialState } from "../context";
import userReducer from "../context/userReducer";
import QrCode from "./QrCode";
import Header from "../Components/Header";
import GoogleTagManager from "../util/analytics/gtm";

import ScanningErrorImage from "../assets/images/scanningError.svg";

function Home() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [store, dispatch] = useReducer(userReducer, IntialState);
  const [memberId, setmemberId] = useState<any>(
    localStorage.getItem("memberId") || null
  );
  const [qrTutorial, setQrTutorial] = useState<any>(null);
  const [qrCodeNotFound, setQrCodeNotFound] = useState<boolean>(false);
  const [qrErrorMessage, setQrErrorMessage] = useState<any>();
  const [isMobileApp, setIsMobileApp] = useState<boolean>(false);
  const history = useHistory();
  const getQueryParam = () => {
    const params: any = queryString.parse(history.location.search);
    const qrCode: any = params.q || localStorage.getItem("qrCode");
    if (params.request_source && params.request_source === "mobile_app") {
      setIsMobileApp(true);
      const jsFile = document.createElement("script");
      jsFile.setAttribute("type", "text/javascript");
      jsFile.setAttribute("src", "/appRedirect.js");
      document.getElementsByTagName("head")[0].appendChild(jsFile);
    }
    if (params.q) {
      localStorage.setItem("qrCode", params.q);
    }
    if (qrCode) {
      const consumerId: any = localStorage.getItem("memberId") || null;
      if (consumerId) {
        setmemberId(consumerId);
        verifyQrCode(qrCode, params.v);
      }
    } else {
      setQrCodeNotFound(true);
    }
  };

  const verifyQrCode = async (qrCode: any, version?: any) => {
    const mobileNumber = localStorage.getItem("mobileNumber") || "";
    try {
      const result = await store.api.verifyQrcode({
        mobileNumber: mobileNumber || "",
        qrCode: qrCode,
        deviceId: "",
        deviceType: "website",
        version: version || "v2",
        vendorCode: "mgp",
      });
      if (result.status) {
        setQrTutorial(result.data?.data.properties);
        const webEngageDataLayer = {
          productName: result.data?.data.properties?.name,
          status: "",
          userType: "Member",
          sku: result.data?.data.properties?.vendorProductId,
        };
        GoogleTagManager.QRCodeScanned(webEngageDataLayer);
      }
    } catch (error) {
      setQrErrorMessage(error.response?.data?.message);
      setQrCodeNotFound(true);
      localStorage.removeItem("qrCode");
    }
  };

  useEffect(() => {
    getQueryParam();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {!isMobileApp && <Header />}
      <div className={`${!isMobileApp ? "header-spacing" : ""}`}>
        {!memberId && !qrCodeNotFound && (
          <Login
            onSuccess={() => {
              getQueryParam();
            }}
            qrCodeNotFound={qrCodeNotFound}
          />
        )}
        {memberId && !qrCodeNotFound && <QrCode tutorial={qrTutorial} />}
        {/* {qrCodeNotFound && (
        <>
          <div className="qr-not-found">
            <div>
              <img src="/assets/images/oops.png" alt="loading" />
            </div>
            OOPS! IT SEEMS THAT YOU HAVE NOT SCANNED YOUR PRODUCT PROPLERY,
            PLEASE TRY SCAN YOUR PRODUCT AGAIN!
          </div>
        </>
      )} */}

        {qrCodeNotFound && (
          <>
            <div className="mobile-wrapper text-center">
              <div className="content-wrapper">
                <img
                  className="scanning-img"
                  src={ScanningErrorImage}
                  alt="scanning"
                />
                {qrErrorMessage ? (
                  <div
                    className="went-wrong-message"
                    dangerouslySetInnerHTML={{ __html: qrErrorMessage }}
                  />
                ) : (
                  <>
                    <div className="went-wrong-message">
                      <strong>Uh oh! Something’s wrong,</strong>
                      <div>we can’t authenticate your MyGlamm product.</div>
                      <br />
                      {/* Share your mobile number and our Customer Care Team will reach
                out to you. */}
                      OOPS! IT SEEMS THAT YOU HAVE NOT SCANNED YOUR PRODUCT
                      PROPLERY, PLEASE TRY SCAN YOUR PRODUCT AGAIN!
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );
}

export default Home;
