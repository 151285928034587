function userReducer(state: any, action: any) {
  switch (action.type) {
    case "ADD_USER": {
      return {
        ...state,
        userProfile: action.payload,
      };
    }
    case "REMOVE_USER": {
      return {
        ...state,
        userProfile: null,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export default userReducer;
