const EmbedVideoUrl = (url: any) => {
  // embed youtube url
  let defaultUrl = "https://www.youtube.com/embed/";
  // eslint-disable-next-line no-useless-escape
  var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  var match = url.match(regExp);
  if (match && match[2].length === 11) {
    let embeddUrl: string;
    embeddUrl = defaultUrl + match[2] + "?rel=0&amp;autoplay=1";
    return embeddUrl;
  } else {
    return url;
  }
};

export default EmbedVideoUrl;
