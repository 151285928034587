import axios, {
  CancelTokenSource,
  AxiosRequestConfig,
  AxiosInstance,
} from "axios";

const isServer = () => typeof window === `undefined`;

class MyGlammAPI {
  protected apiurl: string;

  protected apiurlV2: string;

  protected API_KEY: string;

  protected HEADERS: any;

  protected APIVendor: any = "mgp";

  protected CountryFilter: string;

  protected LanguageFilter: string;

  public PendingRequest: CancelTokenSource;

  protected myGlammV2: AxiosInstance;
  props: any;

  static async getReCaptchToken(action: string): Promise<string> {
    const recaptchaPromise: Promise<string> = new Promise((resolve) => {
      try {
        (window as any).grecaptcha.enterprise.ready(() => {
          (window as any).grecaptcha.enterprise
            .execute(process.env.REACT_APP_RECAPTCH_KEY, { action })
            .then((token: string) => resolve(token));
        });
      } catch {
        resolve("");
      }
    });

    const reCaptchaToken = await recaptchaPromise;
    return reCaptchaToken;
  }

  constructor() {
    this.apiurl = process.env.REACT_APP_BASE_URL || "";
    this.apiurlV2 = process.env.REACT_APP_APIV2_URL || "";
    this.API_KEY = process.env.REACT_APP_API_KEY || "";
    this.APIVendor = "mgp";
    this.CountryFilter = "IND";
    this.LanguageFilter = "EN";
    this.HEADERS = {
      "Content-Type": "application/json",
    };

    /**
     * Cancel Any Pending AJAX Requests before unmounting Components
     * to avoid side effects on state mutations.
     */
    const { CancelToken } = axios;
    this.PendingRequest = CancelToken.source();

    this.myGlammV2 = axios.create({
      baseURL: this.apiurlV2,
      timeout: 15000,
      cancelToken: this.PendingRequest.token,
      headers: this.HEADERS,
    });

    this.myGlammV2.interceptors.request.use(
      async (config: AxiosRequestConfig) => {
        const API_KEY = process.env.REACT_APP_API_KEY || "";
        let xtoken: string | null = null;
        let stoken: string | null = null;

        let { headers } = config;

        /* ReCaptcha Logic if Key Enabled any set of params sent in api */
        if (process.env.REACT_APP_RECAPTCH_KEY) {
          const { rcAction, rcApi } = config.params || {};
          if (rcAction) {
            const recaptchaToken = await MyGlammAPI.getReCaptchToken(rcAction);
            if (recaptchaToken) {
              headers = {
                ...headers,
                "g-recaptcha-response": recaptchaToken,
                "g-recaptcha-action": rcAction,
              };
            }
          }

          /* Use ReCaptcha API only incase toekn is received */
          if (headers?.["g-recaptcha-response"] && rcApi) {
            config.url = rcApi;
          }
        }
        // -----------------------------------------------------------------

        if (!isServer()) {
          xtoken = localStorage.getItem("xtoken");
          stoken = localStorage.getItem("stoken");
        }

        if (stoken || xtoken) {
          headers = { ...headers, "x-access-token": stoken || xtoken };
        } else {
          headers = { ...headers, apikey: API_KEY };
        }

        return {
          ...config,
          headers,
          params: {
            vendorCode: this.APIVendor,
            countryFilter: this.CountryFilter,
            languageFilter: this.LanguageFilter,
          },
        };
      },
      (err: any) => Promise.reject(err)
    );

    this.myGlammV2.interceptors.response.use(undefined, (error: any) => {
      if (error.config && error.response && error.response.status) {
        const { config, response } = error;
        const { status } = response;

        switch (status) {
          /**
           * If consumer is unauthorized or Forbidden to access
           * then force logout and redirect to Login page
           */
          case 401:
          case 403: {
            localStorage.clear();
            if (!isServer()) {
              return this.props.history.push("/");
            }
            break;
          }

          /**
           * Refresh Token - If token is expired then remove old token and
           * replace with new one from response.
           */
          case 498: {
            config.headers["x-access-token"] = response.data.newToken;
            localStorage.setItem("xtoken", response.data.newToken);
            return axios.request(config);
          }

          default: {
            return Promise.reject(error);
          }
        }
      }

      return Promise.reject(error);
    });
  }
}

export default MyGlammAPI;
