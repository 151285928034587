import React, { useRef } from "react";

import useAuth from "../hooks/auth";
import OtpComponent from "../Components/otpComponent";
import ScanningImage from "../assets/images/new-scanning.svg";

import ScanningErrorImage from "../assets/images/scanningError.svg";

const Login = ({ onSuccess, qrCodeNotFound }: any) => {
  const {
    loginFormik,
    otpGenerated,
    timer,
    otpVerified,
    otpFormik,
    isNewUser,
    locationState,
    handleResendOTP,
    store,
  } = useAuth({ onSuccess });

  const buttonRef = useRef<HTMLButtonElement>(null);

  function isNumber(evt: any) {
    const charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      evt.preventDefault();
    } else {
      return true;
    }
    return false;
  }
  function handleCancel() {
    localStorage.removeItem("xtoken");
  }
  // useEffect(() => {
  //   if (isNewUser) {
  //     newUser(isNewUser);
  //   }
  // }, [isNewUser]);

  return (
    <div>
      {!otpGenerated && (
        <>
          <div className="mobile-wrapper text-center mobile-number-section">
            <div className="content-wrapper">
              {qrCodeNotFound && (
                <>
                  <img
                    className="scanning-img"
                    src={ScanningErrorImage}
                    alt="scanning"
                  />
                  <div className="went-wrong-message">
                    <strong>Uh oh! Something’s wrong,</strong>
                    <div>we can’t authenticate your MyGlamm product.</div>
                    <br />
                    Share your mobile number and our Customer Care Team will
                    reach out to you.
                  </div>
                </>
              )}
              {!qrCodeNotFound && (
                <>
                  <img
                    className="scanning-img"
                    src={ScanningImage}
                    alt="scanning"
                  />
                  <h4>
                    Login to authenticate your product, earn Rewards and view a
                    Tutorial on how to use your product
                  </h4>
                </>
              )}

              <form onSubmit={loginFormik.handleSubmit}>
                <div className="input-wrapper">
                  <span>Mobile Number</span>
                  <input
                    name="mobile"
                    type="text"
                    aria-label="Mobile Number"
                    aria-labelledby="mobile"
                    placeholder="Mobile Number"
                    inputMode="numeric"
                    pattern="[0-9]{10}"
                    defaultValue={
                      store.mobileNumber && store.mobileNumber.number
                    }
                    onChange={loginFormik.handleChange}
                    onBlur={loginFormik.handleBlur}
                    maxLength={10}
                    onKeyPress={isNumber}
                    onSubmit={() => {
                      if (buttonRef.current) {
                        buttonRef.current.click();
                      }
                    }}
                  />
                  {loginFormik.errors.mobile && loginFormik.touched.mobile && (
                    <span className="block text-sm text-center text-red-600">
                      {loginFormik.errors.mobile}
                    </span>
                  )}
                </div>
                <button
                  type="submit"
                  ref={buttonRef}
                  disabled={loginFormik.isSubmitting || !loginFormik.isValid}
                  className="primary-btn"
                >
                  {loginFormik.isSubmitting ? (
                    /* // <Spinner className="relative w-6 mx-auto" /> */
                    <img
                      src="/assets/images/spinner.svg"
                      alt="Loading..."
                      className="btn-loader"
                    />
                  ) : (
                    "Next"
                  )}
                </button>
              </form>
            </div>
          </div>
        </>
      )}
      {otpGenerated && (
        <OtpComponent
          mobileNumber={{
            number: loginFormik.values.mobile,
            isdCode: locationState.countryCode,
          }}
          handleCancel={handleCancel}
          isNewUser={isNewUser}
          formik={otpFormik}
          location={locationState.location}
          // send={xState.send}
          // current={xState.current}
          profile={store.profile}
          handleResendOTP={handleResendOTP}
          timer={timer}
          otpVerified={otpVerified}
        />
      )}
    </div>
  );
};

export default Login;
