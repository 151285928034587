import React from "react";
import HttpAPI from "../apis/HttpAPI";

export const IntialState = {
  userProfile: null,
  userAddress: [],
  userMobileNumber: {
    number: "",
    isdCode: "91",
  },
  api: new HttpAPI(),
};

const Store = React.createContext(IntialState);

export default Store;
