import React, { useEffect, useState } from "react";
import HttpAPI from "../apis/HttpAPI";
import VideoModal from "../Components/VideoModel";
import EmbedVideoUrl from "../hooks/embedVideoUrl";

// import ShadeIcon from "../assets/images/shadeicon.svg";
// import RatingIcon from "../assets/images/rating-icon.svg";
import GreyPlayButton from "../assets/images/grey-play-icon.svg";

const paiseToRupees = (x: number) => (x ? x / 100 : 0);

const QrCode = ({ tutorial }: any) => {
  const [widgetsData, setWidgetsData] = useState<any>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [videoUrl, setVideoUrl] = useState<null | string>(null);
  const [isNewUser, setIsNewUser] =
    useState<any>() || localStorage.get("isNewUser");
  const [domainUrl] = useState(process.env.REACT_APP_DOMAIN_URL || "");
  const [videoThumbnail, setVideoThumbnail] = useState<any>();
  const api = new HttpAPI();

  useEffect(() => {
    if (tutorial) {
      if (tutorial?.video) {
        getThumbnail();
      }
      getWidget();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tutorial]);

  const getWidget = async () => {
    setIsNewUser(localStorage.getItem("isNewUser") ?? false);
    const result = await api.getWidgets("mobile-site-qr-code-widgets");
    setWidgetsData(result.data?.data?.widget);
    addClickEvent();
  };

  const getThumbnail = async () => {
    let encodedUrl = encodeURIComponent(tutorial?.video);
    const thumbnailData = await api.getYoutubeThumbnail(encodedUrl);
    if (thumbnailData.data.status) {
      setVideoThumbnail(thumbnailData?.data?.data?.thumbnailURL);
    }
  };
  const copyToClipboard = (e?: any) => {
    const couponCode: any = document.querySelector(".js-coupon-code");
    if (couponCode) {
      couponCode?.select();
      document.execCommand("copy");
      let copyMessage: any = document.createElement("div");
      copyMessage.innerHTML = "Successfully Copied";
      copyMessage.style.fontSize = "12px";
      copyMessage.style.fontStyle = "bold";
      let code: any = document.querySelector(".code");
      code.parentNode.insertBefore(copyMessage, code.nextSibling);
      setTimeout(() => {
        copyMessage.innerHTML = " ";
      }, 1500);
    }
  };

  const addClickEvent = () => {
    const copyBtn = document.querySelector(".js-copy-btn");
    if (copyBtn) {
      copyBtn?.addEventListener("click", copyToClipboard);
    }
  };

  const handleModal = (videoUrl?: string) => () => {
    setIsOpen(true);
    if (videoUrl) {
      setVideoUrl(videoUrl);
    }
  };
  return (
    <React.Fragment>
      {tutorial && tutorial.thumbnail && (
        // {/* product banner for qr code */}
        <div className="wipe-out-wrapper text-center">
          {/* <p>You're winning the war on germs!</p>
          <h2>Remember to WIPEOUT after every touch.</h2> */}
          <img
            src={tutorial.thumbnail}
            alt="wipeout-banner"
            className="img-responsive"
          />
        </div>
      )}
      {widgetsData?.map((widget: any) => {
        if (
          widget.customParam === "single-makeup-tutorial" &&
          widget.commonDetails?.description
        ) {
          return (
            <React.Fragment key={widget.id}>
              {tutorial && tutorial.video && (
                <div className="use-above-fold text-center">
                  <h1>Here’s how to use your product</h1>

                  <div
                    className="banner-wrapper"
                    onClick={handleModal(EmbedVideoUrl(tutorial.video))}
                  >
                    <img className="banner" src={videoThumbnail} alt="no-img" />
                    <img
                      className="play-btn"
                      src={GreyPlayButton}
                      alt="button"
                    />
                  </div>
                </div>
              )}
            </React.Fragment>
          );
        }

        if (
          isNewUser &&
          widget.customParam === "html-content" &&
          widget.commonDetails?.description
        ) {
          return (
            /* Coupon Code*/
            <div
              key={widget.id}
              dangerouslySetInnerHTML={{
                __html: widget.commonDetails.description,
              }}
            />
          );
        }

        if (
          isNewUser &&
          widget.customParam === "multiple-collection-carousel" &&
          widget.commonDetails.descriptionData[0]?.relationalData?.products
        ) {
          const productObject =
            widget.commonDetails.descriptionData[0]?.relationalData?.products;
          return (
            <div className="offers-wrapper" key={widget.id}>
              {/* PRODUCTS SECTION */}
              <div className="top-picks-wrapper">
                <div className="title">
                  <h6>{widget.commonDetails.title}</h6>
                  <p>{widget.commonDetails.subTitle}</p>
                </div>
                <div className="products">
                  {Object.keys(productObject).map((productkey: any) => {
                    const product: any = productObject[productkey];
                    return (
                      <>
                        <a href={domainUrl + product.urlManager?.url}>
                          <div className="product">
                            <div className="left">
                              <div className="rating">
                                {/* <span>4.2</span>
                                <img src={RatingIcon} alt="" /> */}
                              </div>
                              <img
                                src={product.assets[0]?.imageUrl?.["200x200"]}
                                alt="product"
                              />
                            </div>
                            <div className="right">
                              <h2>{product.cms[0]?.content?.name}</h2>
                              <h3>{product.cms[0]?.content?.subtitle}</h3>
                              {/* <div className="shade-wrapper">
                                <img src={ShadeIcon} alt="shade-icon" />
                                <p>6 SHADES</p>
                              </div> */}
                              <div className="price-wrapper">
                                {/* <h6>&#8377;{product.price}</h6>
                              <p>&#8377;{product.offerPrice}</p> */}

                                {product?.price > product?.offerPrice ? (
                                  <>
                                    <h6>
                                      &#8377;
                                      {paiseToRupees(product.offerPrice)}
                                    </h6>
                                    <p>&#8377;{paiseToRupees(product.price)}</p>
                                  </>
                                ) : (
                                  <h6>&#8377;{paiseToRupees(product.price)}</h6>
                                )}
                              </div>
                            </div>
                          </div>
                        </a>
                      </>
                    );
                  })}
                </div>
                {/* SEE MORE */}
                <a
                  href={
                    domainUrl +
                    widget?.commonDetails?.descriptionData[0]?.value[0]
                      ?.urlManager?.url
                  }
                >
                  <button className="see-more-btn text-center">See more</button>
                </a>
              </div>
            </div>
          );
        }
        if (widget.customParam === "single-banner" && widget.commonDetails) {
          return (
            <div className="bottom-banner" key={widget.id}>
              {/* BOTTOM BANNER */}
              <a href={widget?.multimediaDetails[0]?.targetLink}>
                <img
                  className="img-responsive"
                  src={widget?.multimediaDetails[0]?.assetDetails?.url}
                  alt="banner"
                />
              </a>
            </div>
          );
        }
      })}
      {/* Video Modal */}
      <VideoModal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        videoUrl={videoUrl}
      />
    </React.Fragment>
  );
};

export default QrCode;
