import React from "react";
import { CenterModal } from "react-spring-modal";
import "react-spring-modal/dist/index.css";

const VideoModal = ({ isOpen, onRequestClose, videoUrl }: any) => {
  return (
    <CenterModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="VideoModal w-full right-0"
    >
      <div className="relative">
        <button
          type="button"
          onClick={onRequestClose}
          style={{
            top: "-40px",
            fontSize: "29px",
            color: "#9b9b9b",
            outline: "none",
            cursor: "pointer",
          }}
          className="absolute p-3 right-0 font-semibold"
        >
          ×
        </button>
        <iframe
          title="videoId"
          className="absolute w-full h-64"
          src={videoUrl}
          frameBorder="0"
          allowFullScreen
        />
      </div>
    </CenterModal>
  );
};

export default VideoModal;
