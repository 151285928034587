import MyGlammAPI from "./MyGlammAPI";

type APIVendor = "mgp" | "lbr";

type VerifyOTPParams = {
  countryCode: string;
  mobile: string;
  email?: string;
  otp: string;
  type: string;
  vendorCode?: string;
};

type createConsumerPayload = {
  vendorCode?: APIVendor;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  location: {
    countryName: string;
    countryCode: string;
    phoneCode: string;
  };
  meta: {
    registrationInfo: {
      utm?: {}; // Optional
      dob: string;
      appVersion: string;
      deviceType: "Mobile_Website";
      deviceId: string;
    };
  };
  parentReferenceCode?: string;
};

class HttpAPI extends MyGlammAPI {
  //   static myGlammV2: any;
  /**
   *  Widgets
   * @returns Array of Widgets
   */
  public getWidgets(widget: string) {
    return this.myGlammV2
      .get(`/search-ms/widgetGroups/${widget}`, {
        timeout: 5000,
      })
      .then((res: { data: any }) => res.data)
      .catch((error: any) => error);
  }

  /**
   * Generate OTP with V2 API
   * @param params countryCode :string, mobileNumber: string
   */
  public generateOTP(params: { countryCode: string; mobile: string }) {
    return this.myGlammV2.post(
      `/loginFallback`,
      { ...params, vendorCode: this.APIVendor },
      { params: { rcAction: "sendOtp", rcApi: "/v6/otp/generate" } } // reCaptcha Triggered
    );
  }

  /**
   * Verify OTP V2
   * @returns
   */
  public verifyOTP(params: VerifyOTPParams) {
    return this.myGlammV2.post(
      `/members/login`,
      { ...params, vendorCode: this.APIVendor },
      { params: { rcAction: "verifyOtp", rcApi: "/v6/members/login" } }
    );
  }

  /**
   * Register New Consumer
   */
  public createConsumer(payload: createConsumerPayload) {
    return this.myGlammV2.post(`/members/registration`, payload, {
      headers: this.HEADERS,
    });
  }

  /**
   * @description - verify qrCode hash value
   * @param payload
   */
  public verifyQrcode(payload: any) {
    return this.myGlammV2.post(`/members-ms/qr-verifier`, payload, {
      headers: this.HEADERS,
    });
  }

  public getYoutubeThumbnail(youtubeUrl: any) {
    return this.myGlammV2.get(
      `/asset-manager-ms/youTubeVideoDetails/${youtubeUrl}`
    );
  }
  public getTagMaster() {
    return this.myGlammV2.get(
      `/tag-manager-ms/tag-masters?filter={"limit":10,"skip":0,"where":{"and":[{"statusId":1,"tagType":"members"},{"name":{"regexp":"/.*qr-verified.*/i"}}]}}`
    );
  }
}

export default HttpAPI;
