/* eslint-disable no-param-reassign */
class GoogleTagManager {
  static currencyType: any;

  /**
   *
   * @param gaEventObject  - event object containing name and parament
   */
  static gaEventFunc(gaEventObject: any) {
    ConsoleLog(gaEventObject.eventname, gaEventObject.eventobject);
    (window as any).dataLayer.push({
      event: gaEventObject.eventname,
      ecommerce: gaEventObject.eventobject,
      eventCallback: function () {
        return true;
      },
    });
  }

  // /**
  //  * @description - common page view function for GTM
  //  * @param url - current path ot path e.g :- '/product/productslug'
  //  * @param extraParameter - extra value in object which are using in GTM variable
  //  * @param pageType - pageType is valid in case of product page other wise it is undefined. e.g:- pageType = 'product' || undefined
  //  */
  // static gaPageView(url?: any, extraParameter?: any, pageType?: any) {
  //   let dataLayerObject: any = {};
  //   let obj = {
  //     event: "Virtual Page View",
  //     ecommerce: {
  //       url: url ? url : "/",
  //     },
  //     trackPage: pageType ? pageType : undefined, //ga condition satisfied if it has some value otherwise it needs undefined for condition check
  //   };

  //   if (extraParameter) {
  //     dataLayerObject = { ...obj, ...extraParameter };
  //   } else {
  //     dataLayerObject = obj;
  //   }

  //   /** check for UTM and added in object */
  //   //   var utmObject = this.common.getUtmParameters()
  //   //   if (utmObject) {
  //   //     dataLayerObject['utm_param'] = utmObject;
  //   //   }

  //   ConsoleLog(obj.event, obj);
  //   if ((window as any).dataLayer) {
  //     (window as any).dataLayer.push(dataLayerObject);
  //   }
  // }

  /**
   *
   * @param registrationObj - Hold the value to be passed to Google tag manager
   */
  static completeRegistration(registrationObj: any) {
    let eventObject: any = {
      eventobject: {
        Identity: registrationObj.Identity,
        Gender: "female",
        status: "success",
        value: 0,
        currency: "INR",
        webengage: {
          userDetails: registrationObj.webengage,
          registrationCompleted: {
            medium: registrationObj.webengage.medium,
            referral: registrationObj.webengage.referral,
            userType: registrationObj.webengage.userType,
          },
        },
      },
    };
    eventObject.eventname = "registration_completed";
    this.gaEventFunc(eventObject);
  }

  /**
   *
   * @param loginObj - Holds the value to be passed to Google tag manger
   */
  static LoginCompleted(userDetails?: any) {
    let eventObject: any = {
      eventobject: {
        webengage: {
          loginCompleted: {
            Identity: localStorage.getItem("memberId") || "",
            Platform: userDetails.platform,
            "User Type": userDetails.userType,
          },
        },
      },
    };
    eventObject.eventname = "Login Completed";
    this.gaEventFunc(eventObject);
  }

  static QRCodeScanned(QR: any) {
    let eventObject: any = {
      eventobject: {
        webengage: {
          qrDetails: QR,
        },
      },
    };
    eventObject.eventname = "QR Code Scanned";
    this.gaEventFunc(eventObject);
  }
}

function ConsoleLog(eventType: any, eventData: any) {
  console.groupCollapsed(
    `%cGTM Event|%c${eventType}| %c@${new Date().getTime()}`,
    "font-weight: bold;color:#222;",
    "font-weight: normal;color:#222;",
    "font-weight: normal;color:#bdbbbb;"
  );
  console.log("%cGTM", "font-weight: bold;color:blue;", eventData);
  console.groupEnd();
}
export default GoogleTagManager;
