import React, { useRef, useEffect, useState } from "react";
import MyglammCoin from "../assets/images/myglamm-coin.png";

interface OTPProps {
  mobileNumber: {
    number: string;
    isdCode: string;
  };
  handleCancel: () => void;
  isNewUser: boolean;
  location: {
    countryCode: string;
    countryName: string;
    phoneCode: string;
  };
  profile: any;
  formik: any;
  handleResendOTP: any;
  otpVerified: any;
  timer: any;
}
const OtpComponent = (props: OTPProps) => {
  const OTPInput = useRef<HTMLInputElement | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, setState] = useState({
    otpResend: false,
  });
  const { formik, isNewUser, handleResendOTP, timer, profile } = props;

  useEffect(() => {
    if (OTPInput.current) {
      OTPInput.current.focus();
    }
  }, [OTPInput]);

  useEffect(() => {
    if (profile && formik.values.name === "") {
      formik.setFieldValue("name", profile.name);
      formik.setFieldValue("email", profile.email || "");
    }
  }, [formik, profile]);

  return (
    <React.Fragment>
      <div className="mobile-wrapper text-center mobile-number-section otp-section">
        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col justify-between mx-auto"
        >
          <div className="content-wrapper">
            {!isNewUser && <p>Welcome back</p>}
            <h3>ENTER OTP TO VERIFY</h3>
            <div className="otp-inputs-wrapper">
              <input
                name="otp"
                type="tel"
                aria-label="Enter OTP"
                aria-labelledby="otp"
                placeholder="Enter OTP"
                className="otp-single-input"
                pattern="^[0-9]...$"
                minLength={4}
                maxLength={4}
                autoComplete="off"
                ref={OTPInput}
                onBlur={formik.handleBlur}
                value={formik.values.otp}
                onChange={formik.handleChange}
              />
            </div>
            {state.otpResend && timer > 0 && (
              <span className="text-center error-msg-otp">
                OTP sent successfully
              </span>
            )}
            {formik.errors.otp && formik.touched.otp && (
              <span className="text-center error-msg-otp">
                {formik.errors.otp}
              </span>
            )}
            {timer > 0 ? (
              <p className="text-center">
                00:{timer < 10 && "0"}
                {timer}
              </p>
            ) : (
              <button
                className="resend-otp-btn"
                type="button"
                onClick={handleResendOTP}
                aria-hidden
                style={{ height: "40px" }}
              >
                Resend OTP
              </button>
            )}
            {isNewUser && (
              <>
                <div className="input-wrapper input-glammpoints">
                  <div className="myglamm-coin">
                    150+
                    <img src={MyglammCoin} alt="coin" />
                  </div>
                  <span>Name</span>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    autoComplete="name"
                    className="border-b-2 border-black mb-2"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.name && formik.touched.name && (
                    <span className="text-center text-red-600 text-sm">
                      {formik.errors.name}
                    </span>
                  )}
                </div>
                <div className="input-wrapper">
                  <span>Email</span>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="email"
                    className="border-b-2 border-black mb-2"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.email && formik.touched.email && (
                    <span className="text-center text-red-600 text-sm">
                      {formik.errors.email}
                    </span>
                  )}
                </div>
              </>
            )}
            <button
              type="submit"
              className="primary-btn"
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {formik.isSubmitting ? (
                // <Spinner className="relative w-4 mx-auto" />
                <img
                  src="/assets/images/spinner.svg"
                  alt="Loading..."
                  className="btn-loader"
                />
              ) : (
                "LEARN HOW TO USE IT & EARN REWARDS"
              )}
            </button>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default OtpComponent;
