import React, { useEffect } from "react";
import "./App.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./pages/Home";

import Store, { IntialState } from "./context";

function App() {
  useEffect(() => {
    if (process.env.REACT_APP_RECAPTCH_KEY) {
      const recaptchaScript = document.createElement("script");
      recaptchaScript.async = true;
      recaptchaScript.type = "text/javascript";
      recaptchaScript.src = `https://www.google.com/recaptcha/enterprise.js?render=${process.env.REACT_APP_RECAPTCH_KEY}`;
      document.body.appendChild(recaptchaScript);
    }
  }, []);

  return (
    <Store.Provider value={IntialState}>
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </Store.Provider>
  );
}

export default App;
